<template>
  <div class="videos">
    <div class="video-box" @click="videoClick">
      <div class="dplayer">
        <div ref="dplayer" playsinline webkit-playsinline></div>
      </div>
    </div>
    <!-- 视频可预览时间 -->
    <div
      class="prevtime-box"
      v-if="
        verifyData.mediaInfo &&
        !verifyData.playable &&
        !verifyData.mediaInfo.isVip &&
        !(verifyData.mediaInfo.vipPrice === 0 && userInfo.vipType != 0)
      "
    >
      <div class="prevtime" v-if="handleUrl() == path">
        <p>视频可试看 {{ this.verifyData.mediaInfo.prevtime }} 秒</p>
        <p><span>购买</span>即可永久观看</p>
      </div>
      <div
        class="prevtime"
        v-if="
          verifyData.mediaInfo &&
          verifyData.mediaInfo.isVip &&
          userInfo.vipType == 0 && handleUrl() == path
        "
      >
        <p>非VIP可试看 {{ this.verifyData.mediaInfo.prevtime }} 秒</p>
        <p><span>充值VIP</span>无限看</p>
      </div>
    </div>
    <div
      class="prevtime-box"
      v-if="
        verifyData.mediaInfo &&
        verifyData.mediaInfo.isVip &&
        userInfo.vipType == 0
      "
    >
      <div class="prevtime" v-if="this.verifyData.ycLeftFreeNum > 0 && handleUrl() == path || (this.verifyData.ycLeftFreeNum <= 0 && this.verifyData.playable && handleUrl() == path)">
        <p>免费次数剩余: {{ this.verifyData.ycLeftFreeNum }}</p>
      </div>
      <div class="prevtime" v-else-if="handleUrl() == path">
        <p>非VIP可试看 {{ this.verifyData.mediaInfo.prevtime }} 秒</p>
        <p><span>充值VIP</span>无限看</p>
      </div>
    </div>
    <!-- 购买弹窗 -->
    <div class="bugPopUps" v-show="isShowbuy">
      <div class="buyPopUps-box">
        <div class="buy-info">
          <p>{{ videoDialog.topBuyInfoText }}</p>
          <p>{{ videoDialog.bottomBuyInfoText }}</p>
        </div>
        <div class="buy-btn-box">
          <span @click="leftBuying">{{ videoDialog.leftBtnText }}</span>
          <span @click="rightBuying">{{ videoDialog.rightBtnText }}</span>
        </div>
      </div>
    </div>
    <!-- 开通会员弹窗 -->
    <videomember
      v-show="videoMemberShow"
      :topicInfo="verifyData.mediaInfo"
      :videoMemberShow="videoMemberShow"
      @videoMemberClose="videoMemberShow = false"
    />
    <!-- 粉丝团弹窗 -->
    <FanDialog
      :isfanShow="isfanShow"
      :fansGroup="verifyData.fansGroup"
      @fanClose="isfanShow = false"
      @fanSuc="fanSuc"
    />
    <!-- 优惠券弹窗购买 -->
    <videopay
      v-show="videoPayShow"
      :topicInfo="verifyData.mediaInfo"
      :videoPayShow="videoPayShow"
      @joinFans="(isfanShow = true), (videoPayShow = false)"
      @videoPayClose="videoPayShow = false"
    />
  </div>
</template>

<script>
import videomember from "@/views/widget/videoMember.vue";
import FanDialog from "@/views/widget/fanDialog.vue";
import videopay from "@/views/widget/videoPay.vue";
import { mapGetters } from "vuex";
import { sendValidWatched } from "@/api/app.js";
import { optRecord } from "@/utils/optLog.js";
export default {
  props: {
    path: {
      require: true,
      type: String,
    },
    verifyData: {
      require: true,
    },
  },
  components: {
    videomember,
    FanDialog,
    videopay,
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      logicCfg: "logicCfg",
      isVip: "isVip",
      videoRoadLine: "videoRoadLine", // 选择的线路
    }),
  },
  data() {
    return {
      video: {
        url: "",
        type: "hls",
      },
      mediaPath: "media/m3u8/",
      videoPlayStatus: false, //播放状态
      isShowbuy: false, //是否显示购买弹窗
      mvPlayer: null, //播放器实例
      // verifyData: {}, //验证视频数据
      isfullscreen: false, //是否是全屏状态
      //视频弹窗数据
      videoDialog: {
        topBuyInfoText: "",
        bottomBuyInfoText: "",
        leftBtnText: "",
        rightBtnText: "",
      },
      videoMemberShow: false, //开通会员弹窗
      isfanShow: false, //购买粉丝团弹窗
      videoPayShow: false, //金币支付弹窗
      sendTerminate: 0, //观看视频的有效时长
      sendTimer: null,
      firstAddLog: true,
    };
  },
  mounted() {
    if (this.handleUrl()) {
      this.initDpVideo();
    }
  },
  // watch: {
  //   path: function (val) {
  //     console.log("watch");
  //     this.video.url = this.getPath(val);
  //   },
  // },
  methods: {
    handleUrl() {
      // console.log(this.verifyData == "{}", "==-=-=-=-=")
      // console.log(this.verifyData, "==-=-=-=-=")
      var url = "";
      if (!this.verifyData.mediaInfo) {
        return false;
      }
      if (this.verifyData.playable === true) {
        url = this.verifyData.mediaInfo.videoUrl;
      } else {
        url =
          this.verifyData.mediaInfo.prevVideoUrl ||
          this.verifyData.mediaInfo.videoUrl;
      }
      return url;
    },
    initDpVideo() {
      // eslint-disable-next-line no-undef
      const player = (this.mvPlayer = new DPlayer({
        container: this.$refs.dplayer,
        autoplay: true,
        theme: "#f21313",
        lang: "zh-cn",
        hotkey: true,
        screenshot: false,
        preload: "auto",
        video: {
          url: this.getPath(this.handleUrl()),
          type: this.video.type,
        },
      }));
      player.on("playing", () => {
        this.setWatchTime(true);
        this.videoPlayStatus = true;
        //视频播放隐藏暂停按钮
        this.videoPlayIcon(false);
        if (this.mvPlayer.video.readyState == 4) {
          this.videoLoading(false);
        }
      });
      player.on("loadeddata", () => {
        // 当自动播放不起作用时，数据加载完成时，执行一遍播放函数
        this.mvPlayer.play();
      });
      player.on("pause", () => {
        this.setWatchTime(false);
        this.videoPlayStatus = false;
        //视频暂停显示按钮
        this.videoPlayIcon(true);
        this.videoLoading(false);
      });
      player.on("waiting", () => {
        //视频数据正在加载打开loading
        this.videoLoading(true);
        //隐藏暂停按钮
        this.videoPlayIcon(false);
      });
      player.on("fullscreen", () => {
        this.isfullscreen = true;
        // 如果没有播放权限禁止进入全屏
        if (this.playCheck() !== true) {
          this.mvPlayer.fullScreen.toggle("browser");
        }
      });
      player.on("fullscreen_cancel", () => {
        this.isfullscreen = false;
      });
      player.on("timeupdate", () => {
        // console.log(this.mvPlayer.video.src)
        // this.mvPlayer.video.src = "http://localhost:8083/d4528e21-784e-4017-b2f2-289a60909f90"
        if (this.videoPlayStatus) {
          this.videoLoading(false);
        }
        if (this.playCheck() !== true && this.mvPlayer.video.readyState == 4) {
          if (this.firstAddLog) {
            this.firstAddLog = false;
            optRecord(1, this.verifyData.mediaInfo);
          }
          this.mvPlayer.pause(); //暂停视频
          this.showVideoDialog(); //显示视频购买弹窗
          //如果是全屏状态，退出全屏
          if (this.isfullscreen) {
            this.mvPlayer.fullScreen.toggle("browser");
          }
        }
        // console.log(this.verifyData.mediaInfo)
        if (this.firstAddLog && this.verifyData.mediaInfo) {
          if (this.verifyData.mediaInfo.playTime > 300) {
            if (
              this.mvPlayer.video.currentTime >
              this.verifyData.mediaInfo.playTime * 0.1
            ) {
              this.firstAddLog = false;
              optRecord(1, this.verifyData.mediaInfo);
            }
          } else if (this.mvPlayer.video.currentTime > 15) {
            this.firstAddLog = false;
            optRecord(1, this.verifyData.mediaInfo);
          }
        }
      });

      player.on("destroy", () => {
        this.sendValidWatchTimes();
      });
    },
    //获取地址
    getPath(path) {
      if (!path) return "";
      let token = localStorage.getItem("Authorization");
      let baseUrl = sessionStorage.getItem("baseUrl");
      let url = `${baseUrl}${this.mediaPath}${path}?Authorization=${token}&c=${this.videoRoadLine.url}`;
      this.video.url = url;
      return url;
    },
    // 统计观看时长
    setWatchTime(bool) {
      if (bool) {
        this.sendTimer = setInterval(() => {
          this.sendTerminate = this.sendTerminate + 1;
        }, 1000);
      } else {
        if (this.sendTimer) {
          clearInterval(this.sendTimer);
          this.sendTimer = null;
        }
      }
    },

    //点击视频
    videoClick() {
      //播放转暂停
      if (this.videoPlayStatus && this.mvPlayer.video.readyState == 4) {
        this.mvPlayer.pause();
      } else {
        //暂停转播放
        if (this.playCheck() !== true && this.mvPlayer.video.readyState == 4) {
          this.mvPlayer.pause();
          this.showVideoDialog();
          //如果是全屏状态，退出全屏
          if (this.isfullscreen) {
            this.mvPlayer.fullScreen.toggle("browser");
          }
        } else {
          this.mvPlayer.play();
          this.isShowbuy = false;
        }
      }
    },
    // 播放按钮
    videoPlayIcon(isPause) {
      var dplayerBezelIcon =
        document.getElementsByClassName("dplayer-bezel-icon")[0];
      if (!dplayerBezelIcon) {
        return;
      }
      if (isPause) {
        dplayerBezelIcon.style.display = "block";
      } else {
        dplayerBezelIcon.style.display = "none";
      }
    },
    // 视频loading
    videoLoading(isLoading) {
      //视频加载成功隐藏loading
      var dplayerLoading = document.getElementsByClassName(
        "diplayer-loading-icon"
      )[0];
      if (!dplayerLoading) {
        return;
      }
      if (isLoading) {
        dplayerLoading.style.display = "block";
      } else {
        dplayerLoading.style.display = "none";
      }
    },
    //检测视频是否能播放
    playCheck() {
      if (JSON.stringify(this.verifyData) == "{}") {
        return false;
      }
      if (
        this.verifyData.mediaInfo.prevVideoUrl &&
        this.handleUrl() == this.verifyData.mediaInfo.prevVideoUrl
      ) {
        // console.log(this.verifyData.mediaInfo.prevtime)
        // console.log(this.verifyData.mediaInfo.prevtime - 3)
        // console.log((this.mvPlayer.video.currentTime <
        //   this.verifyData.mediaInfo.prevtime - 3))
        if (
          (this.mvPlayer.video.currentTime <
            this.mvPlayer.video.duration - 3) ||
          this.verifyData.playable === true
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.mvPlayer.video.currentTime <
            Number(this.verifyData.mediaInfo.prevtime) ||
          this.verifyData.playable === true ||
          (this.verifyData.mediaInfo.vipPrice === 0 && this.userInfo.vipType != 0)
        ) {
          return true;
        }
        return false;
      }
    },
    // 发送有效统计
    sendValidWatchTimes() {
      try {
        let validWatchTimePercent = this.logicCfg.validWatchTimePercent;
        let id = this.verifyData.mediaInfo.id;
        let playTime = this.verifyData.mediaInfo.playTime;
        if (
          validWatchTimePercent == null ||
          !this.sendTerminate ||
          !id ||
          !playTime ||
          (playTime * validWatchTimePercent) / 100 > this.sendTerminate
        )
          return;
        this.$Api(sendValidWatched, id);
      } catch (error) {
        console.log(error);
      } finally {
        this.sendTerminate = 0;
      }
    },
    // 显示视频购买弹窗
    showVideoDialog() {
      this.isShowbuy = true;
      let code = this.verifyData.code;
      let isVip =
        this.userInfo.vipType != 0 ||
        this.verifyData.mediaInfo.vipPriceType == 3;
      switch (code) {
        ///6020: 金币视频,购买后观看
        case 6020:
          this.videoDialog.leftBtnText = isVip ? "充值金币" : "立即购买";
          this.videoDialog.rightBtnText = isVip ? "立即购买" : "购买会员";
          this.videoDialog.topBuyInfoText = `观看完整版需${
            this.verifyData.mediaInfo?.price ?? 0
          }金币`;
          this.videoDialog.bottomBuyInfoText = `${
            isVip ? "我的会员价" : "会员最低"
          }只需 ${this.verifyData.mediaInfo.vipPrice}金币`;
          break;
        ///6021: 免费观看次数已经用完
        case 6021:
          this.videoDialog.leftBtnText = "充值会员";
          this.videoDialog.rightBtnText = "免费领取";
          this.videoDialog.topBuyInfoText = `试看时间已用完`;
          this.videoDialog.bottomBuyInfoText = `邀请好友即可免费看`;
          break;
        ///6028: 非会员无法观看会员视频
        case 6028:
          this.videoDialog.leftBtnText = "邀请赠VIP";
          this.videoDialog.rightBtnText = `购买VIP`;
          this.videoDialog.topBuyInfoText = `免费观影券和试看次数已用完`;
          this.videoDialog.bottomBuyInfoText = `充值VIP可无限观看`;
          break;
        ///6033: 需要加入粉丝团
        case 6033:
          this.videoDialog.leftBtnText = "立即购买";
          this.videoDialog.rightBtnText = "加入粉丝团";
          this.videoDialog.topBuyInfoText = `观看完整版需${
            this.verifyData.mediaInfo?.price ?? 0
          }金币`;
          this.videoDialog.bottomBuyInfoText = `${
            isVip ? "我的会员价" : "会员最低"
          }只需 ${this.verifyData.mediaInfo.vipPrice}金币`;
          break;
      }
    },
    // 弹窗左边按钮
    leftBuying() {
      let code = this.verifyData.code;
      let isVip = this.userInfo.vipType != 0;
      switch (code) {
        ///6020: 金币视频,购买后观看
        case 6020:
          {
            if (isVip) {
              let vipGrap = {
                type: "video",
                vInfo: this.verifyData.mediaInfo,
              };
              sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
              this.$router.push("/wallet");
              return;
            }
            this.videoPayShow = true;
          }
          break;
        ///6021: 免费观看次数已经用完
        case 6021:
          {
            let vipGrap = {
              type: "video",
              vInfo: this.verifyData.mediaInfo,
            };
            sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
            this.$router.push("/vipList");
          }
          break;
        ///6028: 非会员无法观看会员视频
        case 6028:
          this.videoMemberShow = true;
          break;
        ///6033: 需要加入粉丝团
        case 6033:
          this.videoPayShow = true;
          break;
      }
    },
    // 弹窗右边按钮
    rightBuying() {
      let code = this.verifyData.code;
      let isVip = this.userInfo.vipType != 0;
      switch (code) {
        ///6020: 金币视频,购买后观看
        case 6020:
          {
            if (!isVip) {
              let vipGrap = {
                type: "video",
                vInfo: this.verifyData.mediaInfo,
              };
              sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
              this.$router.push("/vipList");
              return;
            }
            this.videoPayShow = true;
          }
          break;
        ///6021: 免费观看次数已经用完
        case 6021:
          this.videoMemberShow = true;
          break;
        ///6028: 非会员无法观看会员视频
        case 6028:
          {
            let vipGrap = {
              type: "video",
              vInfo: this.verifyData.mediaInfo,
            };
            sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
            this.$router.push("/vipList");
          }
          break;
        ///6033: 需要加入粉丝团
        case 6033:
          this.isfanShow = true;
          break;
      }
    },
    // 购买金币视频成功
    buySuc() {
      this.verifyData.playable = true;
      this.initDpVideo();
      this.videoPayShow = false;
      this.isShowbuy = false;
      // this.mvPlayer.play();
    },
    //粉丝团购买成功
    fanSuc() {
      this.$toast("加入粉丝团成功");
      this.verifyData.playable = true;
      this.initDpVideo();
      this.isfanShow = false;
      this.isShowbuy = false;
      // this.mvPlayer.play();
      this.$emit("fanSucvideo");
    },
  },
  beforeDestroy() {
    this.setWatchTime(false);
    if (this.mvPlayer) {
      this.mvPlayer.destroy();
    }
  },
};
</script>
<style lang="scss" scoped>
.videos {
  width: 100%;
  height: 100%;
  background: #000;
  position: relative;
  .prevtime-box {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    color: #fff;
    background: rgba(0, 0, 0, 0.55);
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 10px;
    p {
      line-height: 16px;
    }
    span {
      color: $vermillion;
    }
  }
  .bugPopUps {
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    .buyPopUps-box {
      position: absolute;
      border-radius: 6px;
      top: 50px;
      left: 67.5px;
      z-index: 1;
      width: 240px;
      height: 111px;

      .buy-info {
        height: 61px;
        background-image: linear-gradient(to left, $vermillion, #ff7979);
        border-radius: 6px 6px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          padding: 5px 0;
          color: #fff;
          font-size: 14px;
          text-align: center;
          white-space: pre-wrap;
        }
      }
      .buy-btn-box {
        height: 40px;
        background: #fff;
        border-radius: 0 0 6px 6px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          display: inline-block;
          border-radius: 15px;
          padding: 5px 8px;
          font-size: 14px;
        }
        span:nth-child(1) {
          color: $vermillion;
          border: 1px solid $vermillion;
        }
        span:nth-child(2) {
          background: $vermillion;
          color: #fff;
        }
      }
    }
  }
}

.video-box {
  width: 100%;
  height: 100%;
  background: #000;
}
.dplayer {
  width: 100%;
  height: 100%;
}
/deep/ .dplayer-mobile-play {
  display: none !important;
}
.video-box /deep/ .dplayer-bezel-icon {
  width: 40px;
  background: url("../../assets/png/video_pause.png") center center no-repeat;
  background-size: 100%;
  opacity: 1;
  border-radius: 0;
  display: none;
}
.video-box /deep/ .dplayer-menu {
  display: none;
}
.video-box /deep/ .diplayer-loading-icon {
  display: block;
}
.video-box /deep/ .dplayer-full-in-icon {
  display: none !important;
}
</style>
